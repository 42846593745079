@import url("https://fonts.googleapis.com/css2?family=Domine&family=Edu+QLD+Beginner&family=Kanit:wght@100&family=Kolker+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Oswald&family=Rubik+Distressed&family=Teko&display=swap");

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 12px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
}
